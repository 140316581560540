@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  font-family: 'Nunito', sans-serif;
}

.-nunito {
  font-family: 'Nunito', sans-serif;
  font-weight: 400;

  &.-light {
    font-weight: 300;
  }
  &.-bold {
    font-weight: 700;
  }
}

.-outline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color: white;
  transition: color .2s;
  cursor: default;

  &:hover{
    color: black;
    text-shadow: none;
  }
}