@mixin media-breakpoint-up($size) {
  $xs: 575.98px;
  $sm: 767.98px;
  $md: 991.98px;
  $lg: 1199.98px;
  // Extra small devices
  @if $size == xs {
    @media (min-width: $xs) { @content };
  }
  // Small devices
  @else if $size == sm {
    @media (min-width: $sm) { @content };
  }
  // Medium devices
  @else if $size == md {
    @media (min-width: $md) { @content };
  }
  // Large devices
  @else if $size == lg {
    @media (min-width: $lg) { @content };
  }
}