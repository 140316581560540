@import './fonts.scss';
@import './mixins.scss';

* {
  box-sizing: border-box;
}

:root {
  --background-color: #000;
}

html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  overflow:auto;

  @extend .-nunito;
}

main {
  overflow: visible;
  max-width: 1199.98px;
  margin: 0 auto;
  min-height: 100vh;
}

section {
  padding: 2rem;
  color: #000;
}

nav.hero {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  padding: 2rem;

  > .particles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  > .name {
    position: fixed;
    color: #FFF;
    font-size: 1.5rem;
    opacity: .6;
    z-index: 1;
    left: 2rem;
    top: 1rem;
    transition: color .5s, opacity .1s;
  }

  > .description {
    position: relative;
    color: #FFF;
    font-size: 3rem;
    z-index: 1;
    @include media-breakpoint-up(sm){
      font-size: 3.5rem;
    }
    @include media-breakpoint-up(md){
      font-size: 4.5rem;
    }
    @include media-breakpoint-up(lg){
      font-size: 4.75rem;
    }
  }

  > .scroll-down {
    padding: 1rem;
    margin-bottom: 1.5rem;
    opacity: .4;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-self: center;
    color: #FFF;
    animation: bounce .8s ease-in-out 3s infinite alternate forwards;

    svg {
      transform: translateY(-4px);
    }
  }
}

@keyframes bounce {
  from {
    transform: translateY(0%);
    opacity: .4;
  }

  to {
    transform: translateY(-5px);
    opacity: .7;
  }
}

.marquee-wrapper {
  width: 200px;
  overflow: hidden;
  @include media-breakpoint-up(md){
    width: 300px;
  }
  > .marquee {
    display: inline-block;
    animation: marquee 3s linear infinite;
    white-space: nowrap;
  }
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.hello {
  font-weight: 300;
  font-size: 24px;
  color: white;
}

section.about-me {
  padding-top: 8rem;
  padding-bottom: 4rem;

  .title {
    font-size: 3rem;
    margin-bottom: 5rem;
    @include media-breakpoint-up(sm){
      font-size: 3.5rem;
    }
    @include media-breakpoint-up(md){
      font-size: 4.5rem;
    }
    @include media-breakpoint-up(lg){
      font-size: 5.5rem;
    }
  }

  > .description {
    font-size: 2rem;

    @include media-breakpoint-up(sm){
      font-size: 3rem;
    }
    @include media-breakpoint-up(md){
      font-size: 4rem;
    }
  }
}

section.experience {

  .title {
    font-size: 3rem;
    margin-bottom: 5rem;
    @include media-breakpoint-up(sm){
      font-size: 3.5rem;
    }
    @include media-breakpoint-up(md){
      font-size: 4.5rem;
    }
    @include media-breakpoint-up(lg){
      font-size: 5.5rem;
    }
  }

  .jobs-grid {
    display: flex;
    flex-direction: column;

    .line {
      display: none;
      widows: 90%;
      margin: 0 5%;
      height: 2px;
      margin-top: 1.2rem;
      background-color: #000;
    }

    @include media-breakpoint-up(sm){
      display: grid;
      grid-template-columns: max-content 1fr 3fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
      ". . ."
      ". . .";

      .line {
        display: block;
      }
    }

    .dates{
      @extend .-nunito;
      @extend .-bold;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .job {
    margin-bottom: 3rem;

    > .employer {
      @extend .-nunito;
      @extend .-bold;
      font-size: 2rem;
      margin-bottom: .5rem;

      > span.location {
        font-size: 1.5rem;
        font-weight: 400;
        opacity: .6;
      }
    }
    > .position {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    > .description {
      font-size: 1rem;
      line-height: 2rem;
    }
  }
}

section.personal-projects {
  min-height: 80vh;

  .title {
    font-size: 3rem;
    margin-bottom: 5rem;
    @include media-breakpoint-up(sm){
      font-size: 3.5rem;
    }
    @include media-breakpoint-up(md){
      font-size: 4.5rem;
    }
    @include media-breakpoint-up(lg){
      font-size: 5.5rem;
    }
  }

  > .project {
    > .title {
      @extend .-nunito;
      @extend .-bold;
      font-size: 2rem;
      margin-bottom: 1rem;
    }

    > .status {
      font-size: 1.5rem;
      font-weight: 400;
      opacity: .6;
      margin-bottom: 1rem;

    }

    > .description {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .images {
      padding: 2rem 0px 4rem 0px;
      @include media-breakpoint-up(md){
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1rem;
      }
    }
    .img-wrapper {
      width: 70%;
      margin-left: 15%;
      @include media-breakpoint-up(md){
        width: 60%;
        margin-left: 20%;
      }
      transform:
        rotateX(51deg)
        rotateZ(43deg);
      transform-style: preserve-3d;
      border-radius: 32px;
      box-shadow:
        1px 1px 0 1px #f9f9fb,
        -1px 0 28px 0 rgba(34, 33, 81, 0.01),
        28px 28px 28px 0 rgba(34, 33, 81, 0.25);
      transition:
        .4s ease-in-out transform,
        .4s ease-in-out box-shadow;
    
      &:hover {
        transform:
          translate3d(0px, -16px, 0px)
          rotateX(51deg)
          rotateZ(43deg);
        box-shadow:
          1px 1px 0 1px #f9f9fb,
          -1px 0 28px 0 rgba(34, 33, 81, 0.01),
          54px 54px 28px -10px rgba(34, 33, 81, 0.15);
      }
    }

    .img-wrapper-2 {
      width: 90%;
      margin-left: 5%;
      margin-bottom: 2rem;
      transform:
        perspective(800px)
        rotateY(25deg) scale(0.9)
        rotateX(10deg);
      transition: 0.6s ease all;
    
      &:hover {
        transform:
          perspective(800px)
          rotateY(-15deg)
          translateY(-50px)
          rotateX(10deg)
          scale(1);
      }
    }

    .img-wrapper-2.-reversed {
      transform:
        perspective(800px)
        rotateY(-25deg) scale(0.9)
        rotateX(10deg);
      transition: 0.6s ease all;
    
      &:hover {
        transform:
          perspective(800px)
          rotateY(15deg)
          translateY(-50px)
          rotateX(10deg)
          scale(1);
      }
    }

    img {

      width: 100%;
      border-radius: 16px;
      margin-bottom: 1rem;
    }
  }
}

footer.contact-me{
  padding: 2rem;
  padding-bottom: 5rem;
  position: relative;
  background-color: #000;
  color: #FFF;
  position: relative;

  > .particles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  > .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
  }

  > .chat {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;
    z-index: 1;
  }

  > .information {
    font-size: 18px;
    line-height: 25px;
    position: relative;
    z-index: 1;

    > .email {
      font-weight: bold;
      color: #FFF;
      text-decoration-color: #FFF;
    }
  }

  > .social {
    position: relative;
    z-index: 1;
    padding-top: 2rem;
    
    > .linkedin, > .instagram {
      text-decoration: none;
      color: #FFF;
      margin-right: 1rem;
      > svg {
        width: 2rem;
        height: auto;
      }

      &:hover {
        > svg {
          fill: #FFFFFF44;
        }
      }
    }
  }

  > .copyright {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1rem;
    color:white;
    font-size: 1rem;
    display: block;
    text-align: center;
  }
}

#name[data-theme="dark"] {
    color: #000;
    opacity: 1;
}